<template>
    <v-card outlined>
        <v-card-text class="grey lighten-4">
            <v-row dense v-for="(resume, index) in itemResumen" :key="index">
                <v-col cols="5" sm="12" v-if="resume.service && resume.service.length > 0">
                    <v-list two-line>
                        <v-subheader>
                            <v-spacer />
                        </v-subheader>
                        <v-divider></v-divider>
                        <template v-for="(item, index) in resume.service">
                            <div :key="index">
                                <!-- v-list-item-sub-title -->
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.description }}
                                            <b v-if="item.idTipoServicio == 11 && item.masajista">
                                                - Encargada: {{ item.masajista.nombres }} {{ item.masajista.apellidos }}
                                            </b>
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="item.check_in_time">
                                            Inicio
                                            {{ item.check_in_time | getHora }} - Fin
                                            {{ item.check_out_time | getHora }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <b class="primary--text caption">{{ item.idTipoMoneda === 2 ? '$ ' : 'S/. ' }} {{ item.subTotal }}</b>
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </template>
                    </v-list>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="2">
                    <v-btn color="primary" @click="printerPago">Imprimir</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text v-if="user.id_role != 4">
            <v-card outlined>
                <v-card-title class="grey lighten-4">
                    <v-row dense align="center">
                        <v-col cols="6">
                            <h5>METODO DE PAGO</h5>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <h5 class="secondaryTheme--text">
                                TOTAL :
                                <b>{{ getTipoMoneda }} {{ importeTotal }}</b>
                            </h5>
                            <h5 style="color: red">
                                SALDO :
                                <b>{{ getTipoMoneda }} {{ (importeTotal - saldoPendiente).toFixed(2) }}</b>
                            </h5>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <ItemPagos
                        :external_tipoCambio="external_tipoCambio"
                        :listaPagos="listaPagos"
                        :loading="loadingPagos"
                        :items-per-page="20"
                        @child-itemsCompra="fnItemsCompra"
                    ></ItemPagos>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>
<script>
import { format, parseISO } from 'date-fns'
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import ItemPagos from '@/components/pagos/ItemPagos'

export default {
    components: { ItemPagos },
    filters: {
        getHora(val) {
            if (val) {
                return format(parseISO(val), 'HH:mm')
            }
        }
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            recibido: 0,
            loadingPagos: false,
            itemsCompra: []
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoMoneda', 'paymentMethod']),
        ...mapState('users', ['user']),
        ...mapState('pagos', ['listaPagos', 'external_tipoCambio']),
        ...mapState('ventas', ['datos', 'mixtos', 'privados', 'masajes', 'productos', 'listaProductosOut', 'modalReserva']),
        ...mapGetters('utilities', ['listTipoBancoDestinoOwn']),
        _listaPagos() {
            let items = []
            console.log('listTipoBancoDestinoOwn', this.listTipoBancoDestinoOwn)
            console.log('listaPagos', this.listaPagos)

            if (this.listaPagos.length > 0 && this.listTipoBancoDestinoOwn.length > 0) {
                this.listaPagos.map((x) => {
                    console.log('x', x)

                    const { text: tipoPago } = this.paymentMethod.find((y) => y.id == x.idTipoPago)
                    const { text: _tipoMoneda } = this.tipoMoneda.find((y) => y.id == x.idTipoMoneda)
                    const isFindBank = this.listTipoBancoDestinoOwn.find((y) => y.id == x.idBancoDestinoExternal)
                        ? this.listTipoBancoDestinoOwn.find((y) => y.id == x.idBancoDestinoExternal).description
                        : ''
                    const _tipobanco = x.idBancoDestinoExternal ? isFindBank : { detalle: '' }
                    const _nroOperacion = x.nroOperacion ? x.nroOperacion : ''
                    //console.log('x', x)
                    items.push({
                        ...x,
                        tipoPago: tipoPago,
                        tipobanco: _tipobanco,
                        tipoMoneda: _tipoMoneda,
                        nroOperacion: _nroOperacion
                    })
                })
            }
            console.log('items', items)
            return items
        },
        idTipoMoneda() {
            return this.datos.idTipoMoneda || 1
        },
        tipoCambio: {
            get: function () {
                return this.datos.tipoCambio
            },

            set: function (newValue) {
                this.$store.commit('ventas/UPDATE_DATOS', { tipoCambio: newValue })
            }
        },

        getTipoMoneda() {
            let defaultValue = 1
            if (this.idTipoMoneda) {
                defaultValue = this.idTipoMoneda
            }

            return this.tipoMoneda.find((x) => defaultValue == x.id).text
        },
        itemResumen() {
            return [
                { title: 'Mixtos', importeItem: this.impMixto, service: this.mixtos },
                {
                    title: 'Privados',
                    importeItem: this.impPrivado,
                    service: this.privados
                },
                {
                    title: 'Masajes',
                    importeItem: this.impMasajes,
                    service: this.masajes
                },
                {
                    title: 'Productos',
                    importeItem: this.impProductos,
                    service: this.productos
                }
            ]
        },
        vuelto() {
            const efectivo = parseFloat(this.datos.efectivo)
            const recibido = parseFloat(this.recibido)
            const tarjeta = parseFloat(this.datos.tarjeta)
            const transferencia = parseFloat(this.datos.transferencia)
            const monto = recibido + tarjeta + transferencia

            if (recibido && monto > this.importeTotal) {
                return (monto - this.importeTotal).toFixed(2)
            }
            return 0
        },

        pendiente() {
            const efectivo = parseFloat(this.datos.efectivo)
            const recibido = parseFloat(this.recibido)
            const tarjeta = parseFloat(this.datos.tarjeta)
            const transferencia = parseFloat(this.datos.transferencia)
            const pendiente = this.importeTotal - efectivo - tarjeta - transferencia
            if (pendiente >= 0) {
                return pendiente.toFixed(2)
            }
            return 0
        },
        impMixto() {
            return this.getSubTotal(this.mixtos)
        },
        impPrivado() {
            return this.getSubTotal(this.privados)
        },
        impMasajes() {
            return this.getSubTotal(this.masajes)
        },
        impProductos() {
            return this.getSubTotal(this.productos)
        },
        importeTotal() {
            const suma = this.impMixto + this.impPrivado + this.impMasajes + this.impProductos
            this.$store.commit('ventas/UPDATE_DATOS', { importeTotal: suma })
            return suma.toFixed(2)
        },
        saldoPendiente() {
            let val = 0
            if (this.listaPagos.length > 0) {
                for (const iterator of this.listaPagos) {
                    if (this.idTipoMoneda == 1) {
                        if (iterator.idTipoMoneda == 2) {
                            val += iterator.importe * iterator.tipoCambio
                        } else {
                            val += iterator.importe
                        }
                    } else {
                        if (iterator.idTipoMoneda == 1) {
                            val += iterator.importe / iterator.tipoCambio
                        } else {
                            val += iterator.importe
                        }
                    }
                }
                console.log('val--', val)
            }
            return val
        },

        deudaPendiente() {
            console.log('this.saldoPendiente---', this.saldoPendiente)
            const deuda = Math.max(this.importeTotal - this.saldoPendiente, 0)
            return deuda
        },
        importeTotalTC() {
            const suma = this.impMixto + this.impPrivado + this.impMasajes + this.impProductos
            const importeTotal = this.idTipoMoneda == 2 ? suma * this.tipoCambio : suma
            this.$store.commit('ventas/UPDATE_DATOS', {
                importeTotalTC: importeTotal
            })
            return suma.toFixed(2)
        },
        servicios() {
            return this.servicios.filter((val) => val.idServicio == this.datos.tipoServicio)
        },
        transferencia() {
            return this.datos.transferencia
        },
        efectivo() {
            return this.datos.efectivo
        },
        tarjeta() {
            return this.datos.tarjeta
        }
    },
    watch: {
        recibido(v) {
            if (v) {
                const recibido = parseFloat(v)
                const tarjeta = parseFloat(this.datos.tarjeta)
                const importeTotal = parseFloat(this.importeTotal)
                const transferencia = parseFloat(this.datos.transferencia)
                const montoSuma = recibido + tarjeta + transferencia
                let efectivo = 0
                //console.log(`montoSuma: ${montoSuma}, Importe: ${importeTotal}`)
                if (recibido) {
                    if (montoSuma >= importeTotal) {
                        //console.log('2')
                        efectivo = importeTotal - tarjeta - transferencia
                    } else {
                        //console.log('3')
                        efectivo = recibido
                    }
                }

                this.$store.commit('ventas/UPDATE_DATOS', { efectivo })
            } else {
                this.recibido = 0
            }
        },
        transferencia(v) {
            if (parseFloat(v) > 0) {
                this.$store.commit('ventas/UPDATE_DATOS', { tipoPago: 3 })
            }
        },
        tarjeta(v) {
            if (parseFloat(v) > 0) {
                this.$store.commit('ventas/UPDATE_DATOS', { tipoPago: 2 })
            }
        },
        efectivo(v) {
            if (parseFloat(v) > 0) {
                this.$store.commit('ventas/UPDATE_DATOS', { tipoPago: 1 })
            }
        }
    },
    async mounted() {
        this.$store.commit('pagos/UPDATE_LISTA_PAGOS', [])
        //console.log('tipoCambio---------------', this.external_tipoCambio)
        this.loadingPagos = true
        if (this.modalReserva.step == '2') {
            this.recibido = this.datos.efectivo
        }
        const { mixtos, privados, masajes, productos } = this

        this.$store.commit('ventas/UPDATE_DATOS', {
            privados,
            masajes,
            productos,
            mixtos
        })

        await this.$store.dispatch('pagos/listaPagosById', this.datos)
        await this.$store.dispatch('pagos/tipoCambio')

        this.loadingPagos = false
    },
    methods: {
        getSubTotal(item) {
            if (item) {
                let suma = 0
                item.map((val) => {
                    suma += parseFloat(val.subTotal)
                })
                return suma
            }
            return 0
        },
        async printerReserva() {
            this.$store.dispatch('printer/reservation', this.datos)
        },
        async printerPago() {
            //console.log("tprinterPago ",this.datos)
            this.$store.dispatch('printer/partPago', {
                datos: this.datos,
                pagos: { tipoMoneda: this.getTipoMoneda, total: this.importeTotal, saldo: (this.importeTotal - this.saldoPendiente).toFixed(2) },
                listaPagos: this._listaPagos
            })
        },

        closeModal() {
            this.$store.commit('ventas/SHOW_MODAL', { step: 1, state: false })
        },
        fnItemsCompra(val) {
            this.$store.commit('ventas/UPDATE_LISTA_PAGOS', val)
        }
    }
}
</script>
